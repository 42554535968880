$background: #303030;
$textColor: #ffffff;
$linkColor: #64B5F6;
$mobileWidth: 500px;

html,body,img {
    background-color: $background;
}
body {
    font-family: "Roboto", "Segoe UI", "Helvetica Neue", Arial, BlinkMacSystemFont, -apple-system, sans-serif;
    color: $textColor;
    margin: 0;
    font-size: 1rem;
    line-height: 1.15;
    overflow-y:hidden;
    @media(min-width: $mobileWidth+1)
    {
        overflow-x:hidden;
    }
}
main {
    padding-top:1rem;
    max-width: 44rem;
    margin: 0 auto;
    @media(max-width: $mobileWidth)
    {
        width: 100%;
    }
}
.card
{
    min-height:15em;
}
.controls > span
{
    margin-right:1em;
    min-width:2em;
    display:inline-block;
    &.inactive
    {
        color: gray;
    }
}
.controls
{
    @media(max-width: $mobileWidth)
    {
        font-size:1.1em;
    }
    @media(min-width: $mobileWidth+1)
    {
        left: 50%;
        transform: translateX(-50%);
    }
    position:fixed;
    bottom:0px;
    padding-bottom:1em;
    padding-left:0.5em;
}
.card,.controls
{
    margin: 0 auto;
    @media(max-width: $mobileWidth)
    {
        margin-left:1em;
        max-width:auto;
    }
    user-select: none;
    @media(max-width: $mobileWidth)
    {
        width: 100%;
    }
}
.wrapper
{
    min-height:100em;
}
.kana-romaji-helper
{
    font-size:1.1em;
}
.card
{
    max-width:15em;
    @media(max-width: $mobileWidth)
    {
        max-width:90%;
    }
}
.App {
    text-align: center;
}

.App-link {
  color: #61dafb;
}

.flex-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}
.flex-item:first-child {
    padding-left: 0px;
}
.flex-item:last-child {
    padding-right: 0px;
}
.flex-item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex: 0 1 auto;
}
